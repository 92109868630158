import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "60px 0",
	"sm-padding": "40px 0",
	"min-height": "300px",
	"sm-min-height": "auto",
	"background": "--color-light",
	"display": "flex",
	"flex-direction": "column"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"font": "--base",
			"text-transform": "uppercase",
			"letter-spacing": "1px",
			"max-width": "850px",
			"color": "--secondary",
			"margin": "0px",
			"children": "Vai via con fiducia"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"margin": "8px 0px 32px",
			"font": "--headline2",
			"md-font": "--headline2",
			"color": "--dark",
			"max-width": "850px",
			"children": "Prenota oggi il tuo appuntamento e sperimenta la differenza AutoPrime. Affidati a noi che ci prenderemo cura della tua auto mentre tu ti prenderai cura di tutto il resto. Noi di AutoPrime non ci occupiamo solo delle auto, ma anche degli stili di vita."
		}
	}
};

const Cta = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Text {...override("text")} />
		<Text {...override("text1")} />
		{children}
	</Section>;
};

Object.assign(Cta, { ...Section,
	defaultProps,
	overrides
});
export default Cta;